<template>
  <div>
    <collapse-card
      :model-value="true"
      :show-collapse-button="false"
      disable-collapse
      class="w-full"
    >
      <template #title>
        <span class="mr-2 text-sm font-medium">
          Manual SKU Push
        </span>
      </template>
      <template #cardContent>
        <div class="py-2 px-4">
          <base-input
            v-model="manualSKUPushFormData.submissionId"
            title="Provide Submission ID(s) seperated by semicolon;"
            type="text"
            container-class="flex items-center mb-4"
            label-class="label-w-46"
            label="Submission Id"
            is-required
          />
          <base-input
            v-model="manualSKUPushFormData.contractNumber"
            title="Contract number (optional)"
            type="text"
            container-class="flex items-center mb-4"
            label-class="label-w-46"
            label="Contract Number"
          />
          <div class="flex justify-center">
            <base-button
              variant="btn-primary"
              :disabled="!isPushSKUBtnActive || isPushingSkus"
              class="mr-4"
              text="Push SKU"
              @click="handlePushSKU()"
            />
            <base-button
              variant="btn-link"
              text="Reset"
              @click="handleManualPushSKUFormReset()"
            />
          </div>
        </div>
      </template>
    </collapse-card>
  </div>
</template>

<script>
import { defineAsyncComponent, reactive, computed, ref } from 'vue';
import { useStore } from 'vuex';
import useToastNotifications from '@/hooks/toastNotifications.js';
import { ERROR, SUCCESS, SKU_PUSH_FAILED, SKU_PUSH_SUCCESS } from '@/constants/alerts';

export default {
    name: 'ManualSKUPush',

    components: {
        CollapseCard: defineAsyncComponent(() => import('@/components/CollapseCard.vue')),
        BaseInput: defineAsyncComponent(() => import('@/components/generic-components/BaseInput.vue')),
        BaseButton: defineAsyncComponent(() => import('@/components/generic-components/BaseButton.vue'))
    },

    setup () {
        const store = useStore();
        const { notificationsStack } = useToastNotifications();
        const manualSKUPushFormData = reactive({
            submissionId: '',
            contractNumber: ''
        });
        const isPushSKUBtnActive = computed(() => manualSKUPushFormData.submissionId.length > 0);
        const isPushingSkus = ref(false);
        const handlePushSKU = async () => {
            try {
                isPushingSkus.value = true
                let resp = await store.dispatch('baSubmissions/manualSKUPush', {
                    bodyPayload: {
                        submissionIds: manualSKUPushFormData.submissionId,
                        contractNumber: manualSKUPushFormData.contractNumber
                    }
                });

                if (!resp || !resp.data) throw new Error('No response from the services.')

                if (resp.data.data && resp.data.data.errors && resp.data.data.errors.length){
                  resp.data.data.errors.map(e => {
                    notificationsStack.value.push({
                      type: ERROR,
                      message: `[ ${e.submissionId} ] - ${e.message}`,
                      duration: 60000,
                    })
                  })
                } else {
                  notificationsStack.value.push({
                      type: SUCCESS,
                      message: SKU_PUSH_SUCCESS
                  })
                }

            } catch (err) {
                notificationsStack.value.push({
                    type: ERROR,
                    message: `${SKU_PUSH_FAILED} - ${err}`,
                    duration: 15000,
                });
            } finally {
                isPushingSkus.value = false;
            }
        };
        const handleManualPushSKUFormReset = () => {
            manualSKUPushFormData.submissionId = '';
            manualSKUPushFormData.contractNumber = '';
        };

        return {
            manualSKUPushFormData,
            isPushSKUBtnActive,
            isPushingSkus,
            handlePushSKU,
            handleManualPushSKUFormReset
        };
    }
};
</script>
